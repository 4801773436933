/* styles/styles.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Your custom CSS styles go here */
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300&display=swap");

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  /* font-family: "Ubuntu", sans-serif; */
  font-family: "Inter", sans-serif;
  scroll-behavior: smooth;
}

#bg {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./assets/hologram.jpg");
  background-size: cover;
}

.loading-bar {
  height: 6px;
  width: 1.5%;
  background-color: #007bff;
  transition: width 0.3s ease;
}

.white {
  color: white;
}

.favorite {
  color: rgb(245 158 11);
}

.loading-spinner {
  animation: spin 1.5s linear infinite;
}

.recent-height {
  min-height: 100vh;
}

.scrollable-lg {
  overflow-x: scroll;
  overflow-y: hidden;
}

@media (max-width: 900px) {
  .search-focus {
    position: absolute;
    top: 1rem;
    width: 90%;
  }
  .scrollable {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  #snap-x-mandatory {
    scroll-snap-type: x mandatory;
  }

  #snap-x-mandatory > * {
    scroll-snap-align: start;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: relative;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 200px;
}

.spinner-abs {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 200px;
}

.spinner .path,
.spinner-abs .path {
  stroke: #93bfec;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
}

.spin {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  z-index: 2;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect:focus {
  outline: none !important;
}

#recents {
  min-height: 12rem !important;
}

/**
 * Loading Animation Snippet
 */

.loading-bar-container {
  width: 100%;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}

.loading {
  color: transparent;
  background: linear-gradient(100deg, #dde0e1 30%, #feffff 50%, #dde0e1 70%);
  background-size: 400%;
  animation: loading 1.75s ease-in-out infinite;
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.pacifico-regular {
  font-family: "Pacifico", cursive;
  font-weight: 400;
  font-style: normal;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

/* .password-toggle-icon i {
  font-size: 18px;
  line-height: 1;
  color: #333;
  transition: color 0.3s ease-in-out;
  margin-bottom: 20px;
}

.password-toggle-icon i:hover {
  color: #000;
} */

.landing {
  background-color: #181818;
  color: white;
}

.hero {
  margin-inline: auto;
  width: calc(100vw - 4rem);
}
.landing-header {
  position: relative;
}

@media (max-width: 768px) {
  .hero {
    width: calc(100vw - 2rem);
  }
}
